import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { NoAuthGuard } from 'src/guards/no-auth-guard';
import { Layout as AuthLayout } from 'src/layouts/landing';

// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const StartPage = lazy(() => import('src/pages/auth/firebase/start'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const LoginPage = lazy(() => import('src/pages/auth/firebase/login'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const FirebaseActionsPage = lazy(() => import('src/pages/auth/firebase/firebase-actions'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const RecoverPasswordPage = lazy(() => import('src/pages/auth/firebase/recover-password'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const MagicLinkPage = lazy(() => import('src/pages/auth/firebase/magic-link'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const UnsubscribePage = lazy(() => import('src/pages/auth/unsubscribe'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const AttendancePage = lazy(() => import('src/pages/auth/attendance'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const SurveyPage = lazy(() => import('src/pages/auth/survey'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <NoAuthGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </NoAuthGuard>
    ),
    children: [
      {
        path: 'start',
        element: <StartPage />
      },
      {
        path: 'create',
        element: <FirebaseActionsPage mode="create" />
      },
      {
        path: 'reset',
        element: <FirebaseActionsPage mode="reset" />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'recover',
        element: <RecoverPasswordPage />
      },
      {
        path: 'redirect',
        element: <MagicLinkPage />
      }
    ]
  },
  {
    path: 'unsubscribe',
    element: (
      <AuthLayout>
        <UnsubscribePage />
      </AuthLayout>
    )
  },
  {
    path: 'attendance',
    element: (
      <AuthLayout>
        <AttendancePage />
      </AuthLayout>
    )
  },
  {
    path: 'survey',
    element: (
      <AuthLayout>
        <SurveyPage />
      </AuthLayout>
    )
  }
];
