import type { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { gtmConfig } from './config';
import { CloudTokenProvider } from './contexts/cloud-token-context';
import { FirebaseProvider } from './contexts/firebase/firebase-context';
import { NativeNotificationsProvider } from './contexts/notifications/native-notifications-context';
import { WebNotificationsProvider } from './contexts/notifications/web-notifications-context';
import { ProfileProvider } from './contexts/profile-context';
import { SettingsProvider } from './contexts/settings-context';
import { TenantProvider } from './contexts/tenant-context';
import { useAnalytics } from './hooks/use-analytics';
import { routes } from './routes';
import { maintenanceRoutes } from './routes/maintenance';
import { store } from './store';
import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Theme } from './theme/mui';
import { maintenanceMode } from '.';

// select the correct providersWW
const NotificationsProvider = Capacitor.isNativePlatform()
  ? NativeNotificationsProvider
  : WebNotificationsProvider;

/**
 * The main app component
 *
 * The component loads the global context providers.
 * Each provider should internally show the splash screen until it is ready to proceed.
 *
 * The order in which the providers are loaded is important.
 */
export const App: FC = () => {
  useAnalytics(gtmConfig);

  const element = useRoutes(maintenanceMode ? maintenanceRoutes : routes);

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TenantProvider>
          <CloudTokenProvider>
            <NotificationsProvider>
              <FirebaseProvider>
                <ProfileProvider>
                  <SettingsProvider>
                    <Theme>{element}</Theme>
                  </SettingsProvider>
                </ProfileProvider>
              </FirebaseProvider>
            </NotificationsProvider>
          </CloudTokenProvider>
        </TenantProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
