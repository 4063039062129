import { FC } from 'react';
import { Icon } from '@iconify/react';
import { IconButton, Typography } from '@mui/material';
import { useGetMessageQuery } from 'src/api/getstream-api';

interface ChatToastProps {
  messageId: string;
  icon?: string;
  onLink?: () => void;
}

// NOTE: react toastify seems to be more reliable without MUI components
export const ChatToast: FC<ChatToastProps> = (props) => {
  const { messageId, icon = 'fa6-solid:comment', onLink } = props;

  // Request the actual message content: This requires the GetStream API token,
  // which is injected by the GetStream offline context. The query is
  // invalidated when the token changes.
  const { data } = useGetMessageQuery({ messageId });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Typography
          variant="h6"
          noWrap
        >
          {data?.message.user?.name ?? 'Chat'}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          noWrap
        >
          {data?.message.text ?? 'Neue Nachricht'}
        </Typography>
      </div>
      {onLink && (
        <IconButton onClick={onLink}>
          <Icon icon={icon} />
        </IconButton>
      )}
    </div>
  );
};
