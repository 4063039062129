import { FC, PropsWithChildren, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { RTL } from 'src/components/rtl';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';
import { useSettings } from 'src/hooks/use-settings';
import { createTheme } from '.';

/**
 * Provide Material UI theme from settings and tenant information
 */
export const Theme: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  // user theme settings
  const { contrast, direction, paletteMode, responsiveFontSizes } =
    useSettings();
  // tenant theme settings
  const { style } = useTenantContext();

  const theme = useMemo(
    () =>
      createTheme({
        tenantPalette: style.palette,
        contrast: contrast,
        direction: direction,
        paletteMode: paletteMode,
        responsiveFontSizes: responsiveFontSizes
      }),
    [style, contrast, direction, paletteMode, responsiveFontSizes]
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta
          name="color-scheme"
          content={paletteMode}
        />
        <meta
          name="theme-color"
          content={theme.palette.primary.main}
        />
      </Helmet>
      <RTL direction={direction}>
        <CssBaseline />
        {children}
      </RTL>
    </ThemeProvider>
  );
};
