import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import * as firebaseMessaging from '@firebase/messaging';
import * as Sentry from '@sentry/browser';
import * as firebaseApp from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';
import { updateFirebaseCurrentUser } from 'src/api/capacitor-adapter';
import { SplashScreen } from 'src/components/splash-screen';
import { useNotificationsContext } from 'src/hooks/contexts/use-notifications';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';
import { useHookMemo } from 'src/hooks/use-hook-memo';
import { useMountEffect } from 'src/hooks/use-mount-effect';
import { messageServiceWorker } from 'src/libs/sw-registration';
import {
  FirebaseState,
  firebaseInstance,
  initializeFirebase
} from '../../libs/firebase';

/**
 * Provides simplified firebase callbacks
 */
export interface FirebaseCallbacks {
  signInWithEmailLink: (
    email: string,
    url: string
  ) => Promise<firebaseAuth.UserCredential>;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<firebaseAuth.UserCredential>;
  signOut: () => Promise<void>;
  updatePassword: (current: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordWithEmailLink: (
    oobCode: string,
    password: string
  ) => Promise<void>;
  validateOobCode: (oobCode: string) => Promise<string>;
}

/**
 * Provides user authentication state
 */
export interface AuthState {
  // login state
  initialized: boolean;
  authenticated: boolean;
  failed: boolean;
  // firebase user (provided for re-renders)
  firebaseUser?: firebaseAuth.User;
  tokenResult?: firebaseAuth.IdTokenResult;
}

const initialState: AuthState = {
  initialized: false,
  authenticated: false,
  failed: false
};

export type FirebaseContextType = FirebaseCallbacks & AuthState;

export const FirebaseContext = createContext<FirebaseContextType | undefined>(
  undefined
);

/**
 * Resolve the authentication state
 */
const handleFirebaseAuthChanged = async (
  firebaseUser: firebaseAuth.User | null
) => {
  console.info('[firebase] Authentication changed:', !!firebaseUser);

  if (!firebaseUser)
    return {
      initialized: true,
      authenticated: false,
      failed: false
    };

  try {
    const tokenResult = await firebaseUser?.getIdTokenResult();

    return {
      initialized: true,
      authenticated: true,
      failed: false,
      firebaseUser,
      tokenResult
    };
  } catch (error) {
    // ensure firebase error is logged
    console.error(error);

    return {
      initialized: true,
      authenticated: false,
      failed: true
    };
  }
};

/**
 * Helper to improve firebase authentication debugging
 */
const withFirebaseAuth = async <T extends any>(
  firebase: FirebaseState | undefined,
  debugMessage: string,
  debugExtra: Record<string, unknown>,
  callback: (auth: firebaseAuth.Auth) => Promise<T>
) => {
  console.info(`[firebase] ${debugMessage}`);
  if (!firebase) throw new Error('Firebase not initialized');
  return callback(firebase.auth).catch((error) => {
    // ensure the error is properly captured with Sentry
    // (this is critical for login error debugging)
    Sentry.captureException(error, { extra: debugExtra });
    // re-throw the error to the caller
    throw error;
  });
};

/**
 * Provide the firebase logic to children
 *
 * NOTE: This component blocks rendering until the firebase context has been
 * initialized properly. Children can rely on the context being ready.
 *
 * There is no generic auth context, because some authentication actions are
 * provider specific and a useful abstraction is only possible once another
 * provider is concretely deployed.
 */
export const FirebaseProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [state, setState] = useState<AuthState>(initialState);
  const { keys, tenant } = useTenantContext();
  const { handleRequestPermissions } = useNotificationsContext();

  const handleState = useCallback(async (state: AuthState) => {
    setState(state);
    // provide the token to the service worker
    await messageServiceWorker('FIREBASE_TOKEN', state.tokenResult);
  }, []);

  const handleTenant = useCallback((tenantId?: string) => {
    // update tenant in the current instance
    if (firebaseInstance) {
      console.info('[firebase] Tenant changed:', tenantId ?? 'none');
      firebaseInstance.auth.tenantId = tenantId || null;
    }

    // provide tenant to Sentry when available
    Sentry.setTag('tenant', tenantId || 'unknown');
  }, []);

  // initialize firebase
  // (returns a local copy which can be used to track changes)
  useMountEffect(async () => {
    console.info('[firebase] Initialization');

    const app = firebaseApp.initializeApp(keys);
    const auth = firebaseAuth.getAuth();
    const messaging = (await firebaseMessaging.isSupported())
      ? firebaseMessaging.getMessaging(app)
      : undefined;

    // initialize global firebase instance
    // (later tenant changes are handled by an effect hook)
    initializeFirebase(
      {
        app,
        auth,
        messaging
      },
      tenant?.tenantId ?? null
    );

    firebaseAuth.setPersistence(auth, firebaseAuth.browserLocalPersistence);
    firebaseAuth.onAuthStateChanged(auth, async (user) => {
      // inject the user into the API
      updateFirebaseCurrentUser(user);
      // update the local state (and cause re-render)
      await handleFirebaseAuthChanged(user).then(handleState);
      // request notifications when authentication changes
      if (!!user) await handleRequestPermissions('Firebase auth changed');
    });

    // ensure initial login is completed
    await auth.authStateReady();
    // initialize remaining state
    return firebaseInstance;
  });

  useEffect(
    () => handleTenant(tenant?.tenantId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenant?.tenantId]
  );

  const signInWithEmailAndPassword = useCallback(
    async (
      email: string,
      password: string
    ): Promise<firebaseAuth.UserCredential> =>
      withFirebaseAuth(
        firebaseInstance,
        'Sign in with email/password',
        { email },
        (auth) => firebaseAuth.signInWithEmailAndPassword(auth, email, password)
      ),
    []
  );

  const signInWithEmailLink = useCallback(
    async (email: string, url: string): Promise<firebaseAuth.UserCredential> =>
      withFirebaseAuth(
        firebaseInstance,
        'Sign in with email/link',
        { email },
        async (auth) => firebaseAuth.signInWithEmailLink(auth, email, url)
      ),
    []
  );

  const signOut = useCallback(
    async (): Promise<void> =>
      withFirebaseAuth(firebaseInstance, 'Sign out', {}, async (auth) =>
        firebaseAuth.signOut(auth)
      ),
    []
  );

  const resetPassword = useCallback(
    async (email: string): Promise<void> =>
      withFirebaseAuth(
        firebaseInstance,
        'Reset password',
        { email },
        async (auth) => firebaseAuth.sendPasswordResetEmail(auth, email)
      ),
    []
  );

  const updatePassword = useCallback(
    async (current: string, password: string): Promise<void> => {
      const user = firebaseInstance?.auth.currentUser;
      if (!user?.email) return;

      // changing password requires recent re-authentication
      const credential = firebaseAuth.EmailAuthProvider.credential(
        user.email,
        current
      );

      console.info('[firebase] Update password');
      await firebaseAuth.reauthenticateWithCredential(user, credential);
      await firebaseAuth.updatePassword(user, password);
    },
    []
  );

  const resetPasswordWithEmailLink = useCallback(
    async (oobCode: string, password: string) =>
      withFirebaseAuth(
        firebaseInstance,
        'Reset password with email/link',
        {},
        async (auth) =>
          firebaseAuth.confirmPasswordReset(auth, oobCode, password)
      ),
    []
  );

  const validateOobCode = useCallback(
    async (oobCode: string): Promise<string> =>
      withFirebaseAuth(
        firebaseInstance,
        'Validate OOB code',
        {},
        async (auth) => firebaseAuth.verifyPasswordResetCode(auth, oobCode)
      ),
    []
  );

  const context = useHookMemo({
    // auth state
    initialized: state.initialized,
    authenticated: state.authenticated,
    failed: state.failed,
    firebaseUser: state.firebaseUser,
    tokenResult: state.tokenResult,
    // firebase callbacks
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signOut,
    updatePassword,
    resetPassword,
    resetPasswordWithEmailLink,
    validateOobCode
  });

  // children rely on valid context
  if (!state.initialized || state.failed) return <SplashScreen />;

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};
