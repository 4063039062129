import { FC, PropsWithChildren, useCallback, useState } from 'react';
import {
  PermissionStatus,
  PushNotifications
} from '@capacitor/push-notifications';
import { useCloudTokenContext } from 'src/hooks/contexts/use-cloud-token';
import { useHookMemo } from 'src/hooks/use-hook-memo';
import {
  NotificationStatus,
  NotificationsContext
} from './notifications-context';

const notificationPermissionStatus = (
  permission: PermissionStatus
): NotificationStatus => {
  if (permission.receive === 'granted') return 'granted';
  if (permission.receive === 'denied') return 'denied';
  return 'default';
};

const requestPermissions = async (
  current?: PermissionStatus
): Promise<PermissionStatus> => {
  if (current === undefined)
    current = await PushNotifications.checkPermissions();
  if (current.receive === 'prompt')
    current = await PushNotifications.requestPermissions();
  if (current.receive === 'granted') {
    // register for notifications
    await PushNotifications.register();
    // try to enable foreground notifications
    await PushNotifications.createChannel({
      id: 'fcm_default_channel',
      name: 'Default Notifications',
      importance: 5,
      visibility: 1,
      lights: true,
      vibration: true
    });
  }

  return current;
};

/**
 * Provide native notifications to children
 */
export const NativeNotificationsProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  /*
   */

  // store the current permission state
  const [permission, setPermission] = useState<PermissionStatus>();
  // synchronize the cloud token with the backend
  const { storeToken, resetToken } = useCloudTokenContext();

  const handleNotification = useCallback(
    (notification: PushNotificationSchema) => {
      toast(
        <DetailsToast
          title={notification.title}
          secondary={notification.body}
        />,
        { position: 'bottom-center' }
      );
    },
    []
  );

  const handleRequestPermissions = useCallback(
    async (debug: string) => {
      console.info(`[native-notifications] Request permissions: ${debug}`);

      const registration = new Promise<Token>((resolve, reject) => {
        // wait for token to be available
        PushNotifications.removeAllListeners();
        PushNotifications.addListener('registration', resolve);
        PushNotifications.addListener('registrationError', reject);
      }).then(async (token) => {
        // register for (foreground) messages
        PushNotifications.removeAllListeners();
        PushNotifications.addListener(
          'pushNotificationReceived',
          handleNotification
        );

        // synchronize with backend
        await storeToken(token.value);
      });

      // request the permission
      await registerNotifications().then(setPermission);
      // wait for the token
      await registration;
    },
    [handleNotification, storeToken]
  );

  const handleLogout = useCallback(async () => {
    await PushNotifications.unregister();
    // synchronize with backend
    await resetToken();
  }, [resetToken]);

  const context = useHookMemo({
    type: 'capacitor' as const,
    permitted: permission?.receive === 'granted',
    handleRequestPermissions,
    handleLogout,
    notify
  });

  return (
    <NotificationsContext.Provider value={context}>
      {children}
    </NotificationsContext.Provider>
  );
};

/*
  export const getToken = async (debug: string): Promise<string> => {
    console.info(`[native-notifications] Request permissions: ${debug}`);
  
    const registration = new Promise<Token>((resolve, reject) => {
      // wait for token to be available
      PushNotifications.removeAllListeners();
      PushNotifications.addListener('registration', resolve);
      PushNotifications.addListener('registrationError', reject);
    }).then(async (token) => {
      // register for (foreground) messages
      PushNotifications.removeAllListeners();
      PushNotifications.addListener(
        'pushNotificationReceived',
        handleNotification
      );
  
      // synchronize with backend
      await tokenStorage.handleStore(token.value);
    });
  
    // request the permission
    await registerNotifications().then(setPermission);
    // wait for the token
    await registration;
  };
  
  
  const notify = async (title: string, body: string) => {
    toast(
      <DetailsToast
        title={title}
        secondary={body}
      />
    );
  };
  
  
  */
