import { DependencyList, useCallback, useState } from 'react';
import { Promiseable } from 'src/types/common';

// executes an async function as callback and tracks its state
export const useAsyncCallback = <T extends (...args: any[]) => void>(
  callback: (...args: Parameters<T>) => Promiseable<void>,
  deps: DependencyList,
  handlers?: { onError?: (error: any) => void; onSuccess?: () => void }
): [
  (...args: Parameters<T>) => void,
  {
    isRunning: boolean;
    isSuccess: boolean;
    error: unknown;
    resetError: () => void;
  }
] => {
  const [isRunning, setRunning] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState<unknown>();

  // discard the latest error
  const resetError = useCallback(() => setError(undefined), []);

  const handler = useCallback(
    (...params: Parameters<T>) => {
      setSuccess(false);
      setRunning(true);
      Promise.resolve(callback(...params))
        .then(() => {
          setSuccess(true);
          setRunning(false);
          setError(undefined);
          handlers?.onSuccess?.();
        })
        .catch((error: unknown) => {
          console.error(error);
          setError(error);
          setRunning(false);
          handlers?.onError?.(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  return [handler, { isRunning, isSuccess, error, resetError }];
};
