import { useState } from 'react';
import { useAsyncMountEffect } from './use-async-mount-effect';

/**
 * Execute an async callback on mount and store the result
 *
 * IMPORTANT: To avoid race conditions, the callback must not have any
 * dependencies (i.e. the callback must be stable).
 */
export const useAsyncLoader = <T>(
  callback: () => Promise<T>
): [T | undefined, ReturnType<typeof useAsyncMountEffect>] => {
  const [data, setData] = useState<T>();

  const state = useAsyncMountEffect(async () => {
    return callback().then((result) => setData(result));
  });

  return [data, state];
};
