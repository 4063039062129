export const awaitWithFallback = <T, U>(
  awaitable: Promise<T>,
  fallback: U
): Promise<T | U> =>
  awaitable.catch((error) => {
    console.warn(error);
    return fallback;
  });

interface Unwrappable<T> {
  unwrap(): Promise<T>;
}

export const unwrapWithFallback = <T, U>(
  unwrappable: Unwrappable<T>,
  fallback: U
): Promise<T | U> => awaitWithFallback(unwrappable.unwrap(), fallback);
