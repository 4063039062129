import * as firebaseMessaging from '@firebase/messaging';
import * as firebaseApp from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';

/**
 * Provides direct access to firebase
 */
export interface FirebaseState {
  app: firebaseApp.FirebaseApp;
  auth: firebaseAuth.Auth;
  messaging?: firebaseMessaging.Messaging;
}

/**
 * Global firebase instance (initialized by the firebase context)
 */
export let firebaseInstance: FirebaseState | undefined = undefined;

export const initializeFirebase = (
  instance: FirebaseState,
  tenantId: string | null
) => {
  firebaseInstance = instance;
  // set the initial tenant id
  firebaseInstance.auth.tenantId = tenantId;
};
