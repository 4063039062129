import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';
import { PaletteColor } from '@mui/material';
import { Error } from 'src/components/error';
import { SplashScreen } from 'src/components/splash-screen';
import { useAsyncLoader } from 'src/hooks/use-async-loader';
import { FeatureFlag } from 'src/types/features';
import { unwrapWithFallback } from 'src/utils/await-with-fallback';
import { KeysJson, StyleJson, tenantApi } from '../api/tenant-api';

/// Provides information about current tenant
export interface TenantData {
  tenantId: string;
}

/// Provides style for current tenant
export interface TenantStyle {
  appName: string;
  palette: {
    // palette fallbacks are provided by theme
    primaryColor?: PaletteColor;
  };
  strings: {
    group: string;
    groups: string;
  };
  userFeatures: FeatureFlag[];
  adminFeatures: FeatureFlag[];
}

export interface TenantState {
  keys: KeysJson;
  tenant?: TenantData;
  style: TenantStyle;
}

export type TenantContextType = TenantState & {
  setTenant: (tenant?: TenantData) => void;
};

export const TenantContext = createContext<TenantContextType | undefined>(
  undefined
);

// provide fallbacks for the style
const validateStyle = (style?: StyleJson): TenantStyle => ({
  appName: style?.appName || 'Verbandspartner',
  palette: style?.palette || {},
  strings: {
    // TODO: improve fallback mechanism
    group: style?.strings?.group || 'Innung',
    groups: style?.strings?.groups || 'Innungen'
  },
  userFeatures: style?.userFeatures ?? ['chat'],
  adminFeatures: style?.adminFeatures ?? ['chat']
});

/**
 * Provide the tenant information to children
 *
 * NOTE: This component blocks rendering until the tenant context has been
 * initialized properly. Children can rely on the context being ready.
 *
 * NOTE: Tenant keys are required. The remaining data can be requested from
 * backend after login, if a generic URL is used.
 */
export const TenantProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  // request tenant specific data
  const [getKeys] = tenantApi.useLazyGetKeysQuery();
  const [getTenant] = tenantApi.useLazyGetTenantQuery();
  const [getStyle] = tenantApi.useLazyGetStyleQuery();

  // custom tenant override
  const [tenantOverride, setTenantOverride] = useState<TenantData>();

  const [remote] = useAsyncLoader(async () => {
    console.info('[tenant] Requesting data');

    const keys = await unwrapWithFallback(getKeys(), undefined);
    const tenant = await unwrapWithFallback(getTenant(), undefined);
    const style = await unwrapWithFallback(getStyle(), undefined);

    // store the tenant data
    console.info('[tenant] Tenant loaded:', tenant?.tenantId ?? 'none');
    return { keys, tenant, style };
  });

  const handleTenant = useCallback(
    (tenant?: TenantData) => setTenantOverride(tenant),
    []
  );

  const context = useMemo(() => {
    // waiting for requests to finish
    if (remote === undefined) return undefined;
    // requests did not return valid data
    if (remote.keys === undefined) return 'error';

    // valid tenant context
    return {
      keys: remote.keys,
      tenant: tenantOverride ?? remote.tenant,
      style: validateStyle(remote.style),
      setTenant: handleTenant
    };
  }, [remote, tenantOverride, handleTenant]);

  // children rely on valid context
  if (context === undefined) return <SplashScreen />;
  if (context === 'error') return <Error />;

  return (
    <TenantContext.Provider value={context}>{children}</TenantContext.Provider>
  );
};
