import { createApi } from '@reduxjs/toolkit/query/react';
import { getStreamConfig } from 'src/config';
import {
  CapacitorBaseQueryArgs,
  capacitorBaseQuery
} from './capacitor-adapter';

let getStreamToken: string | undefined = undefined;

export interface User {
  name?: string;
}

export interface Message {
  text?: string;
  user?: User;
}

export interface Channel {
  id?: string;
  name?: string;
  last_message_at?: string;
}

export interface ChannelWithMessages {
  channel: Channel;
  messages: Message[];
}

export interface UnreadMessages {
  total_unread_count: number;
  total_unread_threads_count: number;
}

const withGetStreamAuth = (args: CapacitorBaseQueryArgs) => ({
  ...args,
  headers: getStreamToken
    ? {
        ...args.headers,
        Authorization: getStreamToken,
        'Stream-Auth-Type': 'jwt'
      }
    : args.headers,
  params: {
    ...args.params,
    api_key: getStreamConfig.apiKey
  }
});

/**
 * GetStream API to avoid websocket connection
 *
 * Once the websocket connection is established, the user will count as active.
 * The number of active users must be kept low.
 */
export const getStreamApi = createApi({
  reducerPath: 'getStreamApi',
  baseQuery: capacitorBaseQuery({
    baseUrl: 'https://chat.stream-io-api.com'
  }),
  tagTypes: ['token', 'unread'],
  endpoints: (build) => ({
    getChannels: build.query<ChannelWithMessages[], { uid: string }>({
      query: ({ uid }) =>
        withGetStreamAuth({
          url: '/channels',
          method: 'POST',
          body: {
            filter_conditions: { members: { $in: [uid] } },
            sort: [{ field: 'last_message_at', direction: -1 }]
          }
        }),
      transformResponse: (response: { channels: ChannelWithMessages[] }) =>
        response.channels,
      providesTags: ['token']
    }),
    getUnread: build.query<UnreadMessages, void>({
      query: () =>
        withGetStreamAuth({
          url: '/unread',
          method: 'GET'
        }),
      providesTags: ['token', 'unread']
    }),
    getMessage: build.query<{ message: Message }, { messageId: string }>({
      query: ({ messageId }) =>
        withGetStreamAuth({
          url: `/messages/${messageId}`,
          method: 'GET'
        }),
      providesTags: ['token']
    })
  })
});

export const { useGetChannelsQuery, useGetUnreadQuery, useGetMessageQuery } =
  getStreamApi;

export const updateGetStreamToken = (token?: string) =>
  (getStreamToken = token);
