import { Suspense } from 'react';
import data from '@emoji-mart/data';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { init } from 'emoji-mart';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { setLicenseKey } from 'survey-core';
import { App } from './app';
import AppUrlListener from './components/app-url-listener';
import './libs/sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// date-fns
setDefaultOptions({ locale: de });
// survey-creator
setLicenseKey(
  'ZDQzOTgxOGMtNGQzMC00YjhhLWFlZmMtYzhiNWM2MzYzMWEyOzE9MjAyNC0xMi0zMA=='
);
// emoji-mart
init({ data });

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <AppUrlListener />
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);

// TODO: maintenance mode from backend
export const enableMaintenanceMode = false;
export const maintenanceMode =
  enableMaintenanceMode && !localStorage.getItem('disable-maintenance');
