import type { FC, ReactNode } from 'react';
import { DemoBanner } from 'src/components/demo-banner';
import { Toaster } from 'src/components/toaster';
import { useSections } from './config';
import { VerticalLayout } from './vertical-layout';

interface LayoutProps {
  direction?: 'vertical' | 'horizontal';
  children?: ReactNode;
}

/**
 * Default layout after login
 */
export const Layout: FC<LayoutProps> = (props) => {
  const { direction, ...other } = props;
  const sections = useSections();
  /*
  if (direction === 'horizontal')
    return (
      <HorizontalLayout
        navColor="discreet"
        sections={sections}
        {...other}
      />
    );
*/
  // use vertical layout by default
  return (
    <>
      <DemoBanner />
      <VerticalLayout
        navColor="discreet"
        sections={sections}
        {...other}
      />
      <Toaster />
    </>
  );
};
