import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import * as firebaseMessaging from '@firebase/messaging';
import { useCloudTokenContext } from 'src/hooks/contexts/use-cloud-token';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';
import { useHookMemo } from 'src/hooks/use-hook-memo';
import { firebaseInstance } from 'src/libs/firebase';
import { swRegistrationPromise } from 'src/libs/sw-registration';
import {
  NotificationStatus,
  NotificationsContext,
  notify
} from './notifications-context';

const notificationPermissionStatus = (
  permission: NotificationPermission
): NotificationStatus => {
  if (permission === 'granted') return 'granted';
  if (permission === 'denied') return 'denied';
  return 'default';
};

const requestPermissions = async (
  current?: NotificationPermission
): Promise<NotificationStatus> => {
  if (current === undefined) current = Notification.permission;
  // NOTE: iOS always returns "denied", unless user has already "granted" _before_
  if (current === 'default') current = await Notification.requestPermission();
  return notificationPermissionStatus(current);
};

/*
const notify = async (title: string, body: string) => {
  await swRegistration?.showNotification(title, {
    body: body
  });
};

export const NotificationsContext = createContext<
  NotificationsContextType<'web'> | undefined
>(undefined);
*/

export const WebNotificationsProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  // store the current permission state
  const [status, setStatus] = useState<NotificationPermission>('default');
  const { storeToken, resetToken } = useCloudTokenContext();
  const { keys } = useTenantContext();

  const handleNotification = useCallback(
    (notification: firebaseMessaging.MessagePayload) => {
      notify({
        sender: notification.data?.sender,
        data: notification.data,
        title: notification.notification?.title,
        body: notification.notification?.body
      });
    },
    []
  );

  const handleRequestPermissions = useCallback(
    async (reason: string) => {
      console.info(`[web-notifications] Request permissions: ${reason}`);

      // notifications must be registered immediately to work on iOS
      // (as this method is called directly from button click)
      if (!firebaseInstance?.messaging) return;

      const status = await requestPermissions();
      setStatus(status);

      if (status === 'granted') {
        // reset badge after application start
        // TODO: track unread
        if ('clearAppBadge' in navigator) navigator.clearAppBadge();
        // receive a device specific token
        const token = await firebaseMessaging.getToken(
          firebaseInstance.messaging,
          {
            vapidKey: keys.vapidKey,
            serviceWorkerRegistration: await swRegistrationPromise
          }
        );
        // synchronize with backend
        await storeToken(token);
      }
    },
    [storeToken, keys.vapidKey]
  );

  const handleLogout = useCallback(async () => {
    if (firebaseInstance?.messaging)
      await firebaseMessaging.deleteToken(firebaseInstance.messaging);
    // synchronize with backend
    await resetToken();
  }, [resetToken]);

  useEffect(() => {
    // register for (foreground) messages
    if (firebaseInstance?.messaging)
      firebaseMessaging.onMessage(
        firebaseInstance.messaging,
        handleNotification
      );
  }, [handleNotification, status]);

  /*
  // HACK: iOS specific workaround (see registerNotifications())
  // this checks for notification permission changes on path change
  const { pathname } = useLocation();
  useEffect(
    () => {
      if (status === 'denied' && Notification.permission === 'granted')
        // re-run the request permission routine to retain a token
        handleRequestPermissions('Page change');
    },
    // explicitly check on path change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );
  */

  const context = useHookMemo({
    status,
    handleRequestPermissions,
    handleLogout
  });

  return (
    <NotificationsContext.Provider value={context}>
      {children}
    </NotificationsContext.Provider>
  );
};
