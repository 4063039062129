import { FeatureFlag } from 'src/types/features';
import { useMe } from './contexts/use-me';
import { useTenantContext } from './contexts/use-tenant';

export const useFeatureFlags = (...flags: FeatureFlag[]) => {
  const profile = useMe();
  const { style } = useTenantContext();

  return profile.tenantAdmin
    ? flags.map((flag) => style.adminFeatures.includes(flag))
    : flags.map((flag) => style.userFeatures.includes(flag));
};
