import type { FC, ReactNode } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from 'src/components/logos/logo-kh';
import { RouterLink } from 'src/components/router-link';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';
import { paths } from 'src/paths';

const TOP_NAV_HEIGHT: number = 64;

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%'
}));

const LayoutHeader = styled('header')(({ theme }) => ({
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: theme.zIndex.appBar
}));

const LayoutBody = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flex: '1 1 auto'
});

interface LayoutProps {
  children: ReactNode;
}

/**
 * Simple landing page layout
 */
export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  // application name is displayed in the header
  const { style } = useTenantContext();

  return (
    <LayoutRoot>
      <LayoutHeader>
        <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={2}
            sx={{ height: TOP_NAV_HEIGHT }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  height: 48,
                  width: 48
                }}
              >
                <Logo />
              </Box>
              <Box
                sx={{
                  color: 'text.primary',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: 20,
                  fontWeight: 800,
                  letterSpacing: '0.3px',
                  lineHeight: 2.5,
                  '& span': {
                    color: 'primary.main'
                  },
                  pl: 2
                }}
              >
                {style.appName}
              </Box>
            </Stack>
          </Stack>
        </Container>
      </LayoutHeader>
      <LayoutBody>
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: '60px',
              md: '120px'
            }
          }}
        >
          {children}
        </Container>
      </LayoutBody>
    </LayoutRoot>
  );
};
