import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { SplashScreen } from 'src/components/splash-screen';
import { useFirebase } from 'src/hooks/contexts/use-firebase';
import { useSearchParams } from 'src/hooks/use-search-params';
import { paths } from 'src/paths';

/**
 * Guard redirects if user is not authenticated
 */
export const NoAuthGuard: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { authenticated } = useFirebase();

  // check if a return path is provided
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  useEffect(
    () => {
      if (authenticated) {
        console.info('[no-auth-guard] Redirecting (authenticated)');
        // returnTo could be an absolute path
        window.location.href = returnTo ?? paths.index;
      }
    },
    // IMPORTANT: redirect only on authentication changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticated]
  );

  // waiting for redirect
  if (authenticated) return <SplashScreen />;

  // guard passed
  return <>{children}</>;
};
