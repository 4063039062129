import type { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useGetApiAssociationInfoQuery } from 'src/api/deinverband-api';
import { InlineIcon } from './inline-icon';

export const DemoBanner: FC = () => {
  const theme = useTheme();

  const { data } = useGetApiAssociationInfoQuery();

  const text =
    //  'Geplante Wartung: Von Do, 06.02.2025, 16:30 Uhr bis Mo, 09.02.2025, 08:00 Uhr ist die Plattform vorübergehend nicht verfügbar. Vielen Dank für Ihr Verständnis.';
    data?.demoMode ? 'Demo Mode' : undefined;

  if (text)
    return (
      <Box
        sx={{
          py: 1,
          px: 2,
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          justifyContent: 'center',
          display: 'flex',
          // overlap menu
          zIndex: 9999
        }}
      >
        <Typography
          variant="overline"
          sx={{ lineHeight: '1.5em' }}
        >
          <InlineIcon icon="fa6-solid:helmet-safety" />
          {text}
        </Typography>
      </Box>
    );

  return null;
};
