import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Loader } from 'src/components/loader';
import { AuthGuard } from 'src/guards/auth-guard';
import { Layout } from 'src/layouts/default';

// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorCreatePage = lazy(() => import('src/pages/admin/editor/create'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorTargetingPage = lazy(() => import('src/pages/admin/editor/targeting'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorContentPage = lazy(() => import('src/pages/admin/editor/content'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorOccasionPage = lazy(() => import('src/pages/admin/editor/occasion'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorSurveyPage = lazy(() => import('src/pages/admin/editor/survey'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorDocumentsPage = lazy(() => import('src/pages/admin/editor/documents'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const EditorPreviewPage = lazy(() => import('src/pages/admin/editor/preview'));

// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const DraftsPage = lazy(() => import('src/pages/admin/drafts'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const PlannedPage = lazy(() => import('src/pages/admin/planned'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const ManagementPage = lazy(() => import('src/pages/admin/management'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const GroupPage = lazy(() => import('src/pages/admin/management/group'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const CompanyPage = lazy(() => import('src/pages/admin/management/company'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const MemberPage = lazy(() => import('src/pages/admin/management/member'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const TargetingListPage = lazy(() => import('src/pages/admin/management/targeting-list'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const SettingsPage = lazy(() => import('src/pages/admin/settings'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const SignaturePage = lazy(() => import('src/pages/admin/settings/signature'));
// prettier-ignore
// eslint-disable-next-line react-refresh/only-export-components
const TemplatePage = lazy(() => import('src/pages/admin/settings/template'));

const memberPaths = {
  path: 'member/:memberId',
  element: <MemberPage />
};

const companyPaths = {
  path: 'company/:companyId',
  children: [
    {
      index: true,
      element: <CompanyPage />
    },
    memberPaths
  ]
};

const groupPaths = {
  path: 'group/:groupId',
  children: [
    {
      index: true,
      element: <GroupPage />
    },
    companyPaths,
    memberPaths
  ]
};

const listPaths = {
  path: 'list/:listId',
  children: [
    {
      index: true,
      element: <TargetingListPage />
    },
    memberPaths
  ]
};

export const adminRoutes: RouteObject[] = [
  {
    path: 'content',
    element: (
      <AuthGuard>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Layout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'create',
        element: <EditorCreatePage />
      },
      {
        path: 'edit/:mode/:id',
        children: [
          {
            path: 'targeting',
            element: <EditorTargetingPage />
          },
          {
            path: 'content',
            element: <EditorContentPage />
          },
          {
            path: 'occasion',
            element: <EditorOccasionPage />
          },
          {
            path: 'survey',
            element: <EditorSurveyPage />
          },
          {
            path: 'documents',
            element: <EditorDocumentsPage />
          },
          {
            path: 'preview',
            element: <EditorPreviewPage />
          }
        ]
      },
      {
        path: 'drafts',
        element: <DraftsPage />
      },
      {
        path: 'planned',
        element: <PlannedPage />
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Layout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'manage',
        children: [
          {
            index: true,
            element: <ManagementPage />
          },
          groupPaths,
          companyPaths,
          memberPaths,
          listPaths
        ]
      },
      {
        path: 'settings',
        children: [
          {
            index: true,
            element: <SettingsPage />
          },
          {
            path: 'signature/:id',
            element: <SignaturePage />
          },
          {
            path: 'template/:id',
            element: <TemplatePage />
          }
        ]
      }
    ]
  }
];
