import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SplashScreen } from 'src/components/splash-screen';
import { GetStreamProvider } from 'src/contexts/getstream/getstream-context';
import { useFirebase } from 'src/hooks/contexts/use-firebase';
import { useMe } from 'src/hooks/contexts/use-me';
import { paths } from 'src/paths';
import { withSearchParams } from 'src/utils/with-search-params';

/**
 * Guard redirects if user is not authenticated
 */
export const AuthGuard: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { authenticated, firebaseUser } = useFirebase();
  const profile = useMe();

  useEffect(
    () => {
      if (!authenticated) {
        console.info('[auth-guard] Redirecting (not authenticated)');
        // redirect to this page after successful login
        navigate(
          withSearchParams(paths.auth.firebase.login, {
            returnTo: window.location.href
          })
        );
      }
    },
    // IMPORTANT: redirect only on authentication changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticated]
  );

  // waiting for authentication or redirect
  if (!authenticated || !firebaseUser || !profile) return <SplashScreen />;
  // TODO: error screen when profile is not loading

  // guard passed
  return (
    <GetStreamProvider firebaseUser={firebaseUser}>
      {children}
    </GetStreamProvider>
  );
};
