import { type FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';

interface SeoProps {
  title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
  const { title } = props;

  // tenant information is required for SEO
  const { style } = useTenantContext();

  const pageTitle = useMemo(() => {
    const name = style.appName;
    // append application name to title
    return title ? title + ' | ' + name : name;
  }, [title, style.appName]);

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};
