import { FC } from 'react';
import { Icon } from '@iconify/react';
import { IconButton, Typography } from '@mui/material';

interface DetailsToastProps {
  title?: string;
  secondary?: string;
  icon?: string;
  onLink?: () => void;
}

// NOTE: react toastify seems to be more reliable without MUI components
export const DetailsToast: FC<DetailsToastProps> = (props) => {
  const {
    title,
    secondary,
    icon = 'fa6-solid:up-right-from-square',
    onLink
  } = props;

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Typography
          variant="h6"
          noWrap
        >
          {title}
        </Typography>
        {secondary && (
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {secondary}
          </Typography>
        )}
      </div>
      {onLink && (
        <IconButton onClick={onLink}>
          <Icon icon={icon} />
        </IconButton>
      )}
    </div>
  );
};
