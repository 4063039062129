import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useRef,
  useState
} from 'react';
import { Preferences } from '@capacitor/preferences';
import { usePostApiUsersMeCloudMessageTokenMutation } from 'src/api/deinverband-api';
import { SplashScreen } from 'src/components/splash-screen';
import { useHookMemo } from 'src/hooks/use-hook-memo';
import { useMountEffect } from 'src/hooks/use-mount-effect';

export interface CloudTokenState {
  initialized: boolean;
  token?: string;
  date?: number;
}

export interface CloudTokenContextType {
  state: CloudTokenState;
  // token management
  storeToken: (debug: string) => Promise<void>;
  resetToken: () => Promise<void>;
  resendToken: () => Promise<void>;
}

export const CloudTokenContext = createContext<
  CloudTokenContextType | undefined
>(undefined);

const getTokenStorage = (): Promise<CloudTokenState> =>
  Preferences.get({ key: 'notifications.token' })
    .then((storage) => JSON.parse(storage.value || '{}'))
    .catch(() => undefined);

const setTokenStorage = (state: CloudTokenState) =>
  Preferences.set({
    key: 'notifications.token',
    value: JSON.stringify(state)
  });

const removeTokenStorage = () =>
  Preferences.remove({ key: 'notifications.token' });

/**
 * Provide cloud token managment to children
 *
 * The hook synchronizes the cloud token with the backend, when neccessary. A
 * copy of the token is stored in the local storage, to avoid excessive backend
 * calls.
 */
export const CloudTokenProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [state, setState] = useState<CloudTokenState>({ initialized: false });
  // to avoid dependencies in the handlers, store a copy of the token
  const tokenRef = useRef<string | undefined>();

  // backend synchronization
  const [sendToken] = usePostApiUsersMeCloudMessageTokenMutation();

  // restore from storage
  useMountEffect(() => {
    console.info('[cloud-token] Restoring token from storage');

    getTokenStorage()
      .then((storage) => {
        tokenRef.current = storage.token;
        setState({
          ...storage,
          initialized: true
        });
      })
      .catch(console.error);
  });

  const handleStore = useCallback(
    async (token: string) => {
      console.info(`[cloud-token] Token received: ${token} `);

      if (tokenRef.current === token)
        return console.debug('[cloud-token] Token did not change');

      // send changed token to backend
      await sendToken({ setCloudMessageToken: { token } }).unwrap();

      // update local state
      const update = {
        initialized: true,
        token,
        date: Date.now()
      };

      tokenRef.current = token;
      setState(update);
      await setTokenStorage(update);
    },
    [sendToken]
  );

  const handleReset = useCallback(async () => {
    console.info('[cloud-token] Token reset');

    // update local state
    tokenRef.current = undefined;
    setState({ initialized: true });
    await removeTokenStorage();
  }, []);

  const handleResend = useCallback(async () => {
    console.info('[cloud-token] Token resend');

    // re-send the current token to the backend
    if (tokenRef.current)
      await sendToken({
        setCloudMessageToken: { token: tokenRef.current }
      }).unwrap();
  }, [sendToken]);

  const context = useHookMemo({
    state,
    // token management
    storeToken: handleStore,
    resetToken: handleReset,
    resendToken: handleResend
  });

  if (!state.initialized) return <SplashScreen />;

  return (
    <CloudTokenContext.Provider value={context}>
      {children}
    </CloudTokenContext.Provider>
  );
};
