import { useMemo } from 'react';

/**
 * Helper to memoize dictionary of hooks
 *
 * This is useful for controller (or similar) hooks, which return a collection
 * of other hooks. If the returned dictionary is not destructured in the caller
 * component immediately, it needs to be memoized,
 */
export const useHookMemo = <T extends { [key: string]: unknown }>(
  hooks: T
): T => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => hooks, Object.values(hooks));
};
