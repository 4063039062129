/* eslint-disable react-refresh/only-export-components */
import { createContext } from 'react';
import { ToastOptions, toast } from 'react-toastify';
import { getStreamApi } from 'src/api/getstream-api';
import { ChatToast } from 'src/components/toasts/chat-toast';
import { DetailsToast } from 'src/components/toasts/details-toast';
import { store } from '../../store';

/**
 * Generic notification status
 */
export type NotificationStatus = 'granted' | 'denied' | 'default';

/**
 * Generic notifications context interface
 */
export interface NotificationsContextType {
  status: NotificationStatus;
  handleRequestPermissions: (reason: string) => Promise<void>;
  handleLogout: () => Promise<void>;
}

export const NotificationsContext = createContext<
  NotificationsContextType | undefined
>(undefined);

/**
 * Generic notification payload
 */
export interface NotifyPayload {
  sender?: string;
  data?: any;
  title?: string;
  body?: string;
}

/**
 * Handle a notification (i.e. show a toast)
 */
export const notify = async ({ sender, data, title, body }: NotifyPayload) => {
  const options: ToastOptions = {
    position: 'bottom-center',
    autoClose: false
  };

  if (sender === 'stream.chat') {
    // unread message have probably changed
    store.dispatch(getStreamApi.util.invalidateTags(['unread']));
    return toast(<ChatToast messageId={data?.message_id} />, options);
  }

  // default notification
  return toast(
    <DetailsToast
      title={title}
      secondary={body}
    />,
    options
  );
};
