import type { FC } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

export const Toaster: FC = () => {
  // const theme = useTheme();

  return (
    <ToastContainer
      hideProgressBar
      newestOnTop
      position="bottom-right"
      transition={Slide}
    />
  );
};
