import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { enhancedApi } from 'src/api/enhanced-api';
import { useFirebase } from 'src/hooks/contexts/use-firebase';
import { mapUserDetailsToProfile } from 'src/mappings/user-details-to-profile';
import { Profile } from 'src/types/user';
import { getFullName } from 'src/utils/get-full-name';

export const ProfileContext = createContext<Profile | undefined>(undefined);

/**
 * Provide the user's profile information to children
 *
 * NOTE: This component is deployed inside the main app component. This allows
 * updates on logout/when not logged in (i.e. updating Sentry). Therefore, it
 * needs to render children, even when not authenticated (meaning the profile is
 * not available). Children should use the AuthGuard if authentication is
 * required.
 */
export const ProfileProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { authenticated, firebaseUser } = useFirebase();

  const query = enhancedApi.useGetApiUsersMeQuery(undefined, {
    skip: !authenticated
  });

  const profile = useMemo(
    () => mapUserDetailsToProfile(query.data),
    [query.data]
  );

  // update the Sentry user information whenever possible
  // (this might execute multiple times during login)
  useEffect(() => {
    if (authenticated)
      Sentry.setUser({
        id: firebaseUser?.uid,
        email: profile?.email,
        username: getFullName(profile)
      });
    else Sentry.setUser(null);
  }, [authenticated, profile, firebaseUser]);

  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};
