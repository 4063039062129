import { useState } from 'react';
import { useMountEffect } from './use-mount-effect';

// executes only once on mount
export const useAsyncMountEffect = (
  callback: () => Promise<void>,
  debug?: string
) => {
  const [isRunning, setRunning] = useState(true);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState<unknown>();

  useMountEffect(() => {
    if (debug) console.log(`${debug} started...`);
    callback()
      .then(() => {
        if (debug) console.log(`${debug} done`);
        setSuccess(true);
        setRunning(false);
        setError(undefined);
      })
      .catch((error) => {
        if (debug) console.log(`${debug} failed`);
        console.error(error);
        setError(error);
        setRunning(false);
      });
  });

  return { isRunning, isSuccess, error };
};
