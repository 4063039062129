import { PaletteColor } from '@mui/material';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appConfig } from 'src/config';
import { FeatureFlag } from 'src/types/features';
import { capacitorBaseQuery } from './capacitor-adapter';

export interface TenantJson {
  tenantId: string;
}
export interface StyleJson {
  appName?: string;
  palette?: {
    primaryColor?: PaletteColor;
  };
  strings?: {
    group?: string;
    groups?: string;
  };
  userFeatures?: FeatureFlag[];
  adminFeatures?: FeatureFlag[];
}

export interface KeysJson {
  apiKey: string;
  appId: string;
  authDomain: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
  vapidKey: string;
}

export interface TenantResponse {
  name?: string;
}

const debugDisableTenant = false;

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: capacitorBaseQuery({
    baseUrl: appConfig.baseUrl || ''
  }),
  endpoints: (build) => ({
    getTenant: build.query<TenantJson | undefined, void>({
      query: () => ({ url: '/t/tenant.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' },
      // validate response
      transformResponse: (response: TenantResponse) =>
        debugDisableTenant || !response.name
          ? undefined
          : { tenantId: response.name }
    }),
    getStyle: build.query<StyleJson | undefined, void>({
      query: () => ({ url: '/t/style.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' },
      // validate response
      transformResponse: (response: StyleJson) =>
        debugDisableTenant || !response ? undefined : response
    }),
    getKeys: build.query<KeysJson, void>({
      query: () => ({ url: '/static/keys.json' }),
      // avoid problems with file format on remote
      extraOptions: { responseType: 'json' }
    })
  })
});

export const { useGetTenantQuery, useGetStyleQuery, useGetKeysQuery } =
  tenantApi;
