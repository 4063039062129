import type { Direction, PaletteMode, Theme } from '@mui/material';
import {
  PaletteColor,
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@mui/material/styles';
import { createOptions as createBaseOptions } from './base/create-options';
import { withAlphas } from './colors';
import { createOptions as createDarkOptions } from './dark/create-options';
import { createOptions as createLightOptions } from './light/create-options';

export const defaultPrimary: PaletteColor = withAlphas({
  lightest: '#ABDEE0',
  light: '#71DDE3',
  main: '#00A6B0',
  dark: '#096A70',
  darkest: '#1E585C',
  contrastText: '#FFFFFF'
});

declare module '@mui/material/styles' {
  export interface NeutralColors {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface Palette {
    neutral: NeutralColors;
  }

  interface PaletteOptions {
    neutral?: NeutralColors;
  }

  interface PaletteColor {
    lightest?: string;
    darkest?: string;
    alpha4?: string;
    alpha8?: string;
    alpha12?: string;
    alpha30?: string;
    alpha50?: string;
  }

  interface TypeBackground {
    paper: string;
    default: string;
  }
}

export type ColorPreset = 'blue' | 'green' | 'indigo' | 'purple';

export type Contrast = 'normal' | 'high';

interface ThemeConfig {
  tenantPalette?: {
    primaryColor?: PaletteColor;
  };
  contrast?: Contrast;
  direction?: Direction;
  paletteMode?: PaletteMode;
  responsiveFontSizes?: boolean;
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    // base options available for both dark and light palette modes
    createBaseOptions({
      direction: config.direction
    }),
    // options based on selected palette mode, color preset and contrast
    config.paletteMode === 'dark'
      ? createDarkOptions({
          primaryColor: config.tenantPalette?.primaryColor
            ? withAlphas(config.tenantPalette.primaryColor)
            : defaultPrimary,
          contrast: config.contrast
        })
      : createLightOptions({
          primaryColor: config.tenantPalette?.primaryColor
            ? withAlphas(config.tenantPalette.primaryColor)
            : defaultPrimary,
          contrast: config.contrast
        })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    responsive: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    inline: true;
  }
}
