import { type FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';

export const Logo: FC = () => {
  const theme = useTheme();

  // const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  if (!error)
    return (
      <img
        src="/t/navigation-icon.png"
        style={{ objectFit: 'scale-down' }}
        // fallback to default logo on error
        onError={() => setError(true)}
        // onLoad={() => setLoaded(true)}
      />
    );

  const fill = theme.palette.primary.main;

  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="matrix(0.1,0,0,-0.1,-292.37281,1221.6262)"
        stroke="none"
        id="g258"
      >
        <g
          fill={fill}
          opacity={0.16}
        >
          <path
            d="m 3000.9713,12199.333 c -24.5795,-8.823 -34.7854,-12.601 -34.7381,-12.808 0.071,-0.323 24.9347,-66.141 25.0531,-66.279 0.1184,-0.161 69.713,24.926 69.713,25.111 0,0.23 -24.8163,65.794 -25.0768,66.209 -0.095,0.184 -11.6267,-3.847 -34.9512,-12.233 z"
            id="path1004"
          />
          <path
            d="m 3065.8299,12204.839 c 0.095,-0.23 6.2988,-17.07 13.7816,-37.412 7.5065,-20.342 13.6869,-37.045 13.7816,-37.137 0.1657,-0.161 24.4374,50.844 24.4374,51.328 0,0.138 -11.6504,5.482 -25.8582,11.841 -14.2315,6.381 -25.9766,11.657 -26.095,11.703 -0.1184,0.07 -0.1421,-0.07 -0.047,-0.323 z"
            id="path1006"
          />
          <path
            d="m 2935.6864,12137.409 c -6.6066,-13.961 -11.9819,-25.387 -11.9582,-25.41 0.1657,-0.139 53.0662,-23.798 53.1135,-23.752 0.071,0.07 -28.9129,74.296 -29.055,74.434 -0.047,0.05 -5.4936,-11.335 -12.1003,-25.272 z"
            id="path1008"
          />
          <path
            d="m 3119.6065,12128.908 c -7.9327,-15.228 -14.4209,-27.783 -14.3736,-27.921 0.1895,-0.761 25.0531,-66.256 25.1478,-66.256 0.071,0 6.654,12.486 14.6104,27.76 l 14.492,27.737 -12.5739,33.197 c -6.9145,18.246 -12.645,33.174 -12.716,33.174 -0.071,0 -6.6303,-12.463 -14.5867,-27.691 z"
            id="path1010"
          />
          <path
            d="m 2928.4168,12081.589 c 0.2604,-0.691 5.9436,-15.665 12.6212,-33.266 6.6777,-17.623 12.1951,-32.068 12.2424,-32.114 0.2368,-0.207 59.0335,-23.867 59.1045,-23.775 0.071,0.07 -24.9347,66.256 -25.1005,66.417 -0.024,0.02 -13.0001,5.276 -28.8181,11.657 -15.818,6.405 -29.1734,11.795 -29.647,11.98 l -0.8761,0.368 z"
            id="path1012"
          />
          <path
            d="m 3055.3872,12055.396 c -19.0621,-6.842 -34.6434,-12.556 -34.6434,-12.648 0,-0.207 24.3664,-64.574 24.9111,-65.818 l 0.3078,-0.668 34.8091,12.51 c 24.8163,8.915 34.7855,12.578 34.7381,12.808 -0.2131,1.129 -25.0768,66.302 -25.2662,66.302 -0.1184,-0.02 -15.818,-5.644 -34.8565,-12.486 z"
            id="path1014"
          />
        </g>
        <g fill={fill}>
          <path
            d="m 3005.2163,12204.023 c -24.5795,-8.823 -34.7854,-12.601 -34.7381,-12.808 0.071,-0.323 24.9347,-66.141 25.0531,-66.279 0.1184,-0.161 69.713,24.926 69.713,25.111 0,0.23 -24.8163,65.795 -25.0768,66.209 -0.095,0.184 -11.6267,-3.847 -34.9512,-12.233 z"
            id="path180"
          />
          <path
            d="m 3070.075,12209.529 c 0.095,-0.23 6.2988,-17.07 13.7815,-37.412 7.5065,-20.342 13.6869,-37.045 13.7816,-37.137 0.1658,-0.161 24.4375,50.844 24.4375,51.328 0,0.138 -11.6505,5.482 -25.8583,11.841 -14.2314,6.381 -25.9766,11.657 -26.095,11.703 -0.1184,0.07 -0.142,-0.07 -0.047,-0.323 z"
            id="path182"
          />
          <path
            d="m 2939.9314,12142.099 c -6.6066,-13.961 -11.9819,-25.387 -11.9582,-25.41 0.1658,-0.139 53.0662,-23.798 53.1135,-23.752 0.071,0.07 -28.9129,74.296 -29.0549,74.434 -0.047,0.05 -5.4937,-11.335 -12.1004,-25.272 z"
            id="path184"
          />
          <path
            d="m 3123.8515,12133.598 c -7.9327,-15.228 -14.4209,-27.783 -14.3735,-27.921 0.1894,-0.761 25.0531,-66.256 25.1478,-66.256 0.071,0 6.654,12.486 14.6103,27.76 l 14.492,27.737 -12.5739,33.197 c -6.9145,18.246 -12.645,33.174 -12.716,33.174 -0.071,0 -6.6303,-12.463 -14.5867,-27.691 z"
            id="path186"
          />
          <path
            d="m 2932.6618,12086.279 c 0.2605,-0.691 5.9436,-15.665 12.6213,-33.266 6.6776,-17.623 12.195,-32.068 12.2424,-32.114 0.2367,-0.207 59.0334,-23.867 59.1044,-23.774 0.071,0.07 -24.9347,66.255 -25.1004,66.416 -0.024,0.02 -13.0002,5.276 -28.8182,11.657 -15.818,6.405 -29.1734,11.795 -29.647,11.98 l -0.8761,0.368 z"
            id="path188"
          />
          <path
            d="m 3059.6322,12060.086 c -19.0621,-6.842 -34.6433,-12.556 -34.6433,-12.648 0,-0.207 24.3664,-64.574 24.911,-65.818 l 0.3078,-0.668 34.8092,12.51 c 24.8163,8.915 34.7854,12.578 34.738,12.808 -0.2131,1.129 -25.0767,66.302 -25.2662,66.302 -0.1184,-0.02 -15.818,-5.644 -34.8565,-12.486 z"
            id="path190"
          />
        </g>
      </g>
    </svg>
  );
};
