import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import * as firebaseAuth from 'firebase/auth';
import {
  Chat as GetStreamChat,
  Streami18n,
  useCreateChatClient
} from 'stream-chat-react';
import { enhancedApi } from 'src/api/enhanced-api';
import { getStreamApi, updateGetStreamToken } from 'src/api/getstream-api';
import { getStreamConfig } from 'src/config';
import { useHookMemo } from 'src/hooks/use-hook-memo';
import { messageServiceWorker } from 'src/libs/sw-registration';
import { useDispatch } from 'src/store';

// translation to use with the client
const i18n = new Streami18n({
  language: 'de',
  translationsForLanguage: {
    'You have no channels currently': 'Sie sind kein Mitglied eines Chats.'
  }
});

export interface GetStreamAuth {
  uid: string;
  token: string;
}

export interface GetStreamContextType {
  auth?: GetStreamAuth;
  online: boolean;
  channelId?: string;
  connect: (channelId?: string) => void;
}

export const GetStreamContext = createContext<GetStreamContextType | undefined>(
  undefined
);

interface GetStreamProviderProps {
  firebaseUser: firebaseAuth.User;
}

/**
 * Helper component to provide the GetStream client only when online
 */
const GetStreamClientProvider: FC<
  PropsWithChildren<{
    context: GetStreamContextType;
    auth: GetStreamAuth;
  }>
> = ({ context, auth, children }) => {
  const client = useCreateChatClient({
    apiKey: getStreamConfig.apiKey,
    // using a provider is difficult with RTK query and offline mode
    // (the token needs to be available in both components)
    tokenOrProvider: auth.token,
    userData: { id: auth.uid }
  });

  const value = useMemo(
    // delay the online state until the client is available
    // (to avoid flickering of the chat)
    () => ({ ...context, online: !!client }),
    [context, client]
  );

  if (client === null)
    return (
      <GetStreamContext.Provider value={value}>
        {children}
      </GetStreamContext.Provider>
    );

  return (
    <GetStreamContext.Provider value={value}>
      <GetStreamChat
        client={client}
        i18nInstance={i18n}
      >
        {children}
      </GetStreamChat>
    </GetStreamContext.Provider>
  );
};

/**
 * Provide the GetStream chat logic to children (REST API and websocket).
 *
 * IMPORTANT: Requires valid firebase authentication (use inside AuthGuard).
 *
 * NOTE: This component renders children, even when the chat is not available.
 * This avoids blocking the whole application, when just a minor component is
 * causing problems.
 */
export const GetStreamProvider: FC<
  PropsWithChildren<GetStreamProviderProps>
> = (props) => {
  const { firebaseUser, children } = props;

  const dispatch = useDispatch();

  const { data: token } = enhancedApi.useGetApiUsersMeChatTokenQuery(
    undefined,
    {
      // request new token after 4 minutes (expires in 5 minutes)
      // TODO: maybe re-fetch token when it is rejected
      pollingInterval: 4 * 60 * 1000
    }
  );

  const [auth, setAuth] = useState<GetStreamAuth>();
  const [channelId, setChannelId] = useState<string>();

  const connect = useCallback((channelId?: string) => {
    console.info('[getstream-rest] Connecting', channelId);
    // immediately set the channel (to allow for visual feedback)
    setChannelId(channelId);
  }, []);

  useEffect(() => {
    const auth = token
      ? { uid: firebaseUser.uid, token: token.token }
      : undefined;

    console.info('[getstream-rest] Auth changed:', !!auth);
    // inject token into GetStream API (does not trigger re-rendering)
    updateGetStreamToken(auth?.token);
    // update authentication state
    setAuth(auth);
    // invalidate cached offline API queries
    dispatch(getStreamApi.util.invalidateTags(['token']));
    // provide the token to the service worker
    messageServiceWorker('GET_STREAM_TOKEN', auth?.token).catch(console.error);
  }, [dispatch, firebaseUser.uid, token]);

  const context = useHookMemo({
    auth,
    online: false,
    channelId,
    connect
  });

  // once a channel is actively selected, connect to the websocket
  // (this is done in a sub-component to be able to call the client hook conditionally)
  if (channelId && auth)
    return (
      <GetStreamClientProvider
        context={context}
        auth={auth}
      >
        {children}
      </GetStreamClientProvider>
    );

  return (
    <GetStreamContext.Provider value={context}>
      {children}
    </GetStreamContext.Provider>
  );
};
