import { useEffect, useRef } from 'react';

// executes only once on mount
export const useMountEffect = (callback: () => void, debug?: string) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      // optional debug log to ensure execution
      if (debug) console.log(debug);
      callback();
    }
  });
};
