import type { FC } from 'react';
import { Icon } from '@iconify/react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router';
import { SimpleSnackbar } from 'src/components/simple-snackbar';
import { useFirebase } from 'src/hooks/contexts/use-firebase';
import { useNotificationsContext } from 'src/hooks/contexts/use-notifications';
import { useAsyncCallback } from 'src/hooks/use-async-callback';
import { usePopover } from 'src/hooks/use-popover';
import { paths } from 'src/paths';
import { icons } from 'src/theme/icons';
import { AccountPopover } from './account-popover';

export const AccountButton: FC = () => {
  const navigate = useNavigate();
  const firebase = useFirebase();
  const notifications = useNotificationsContext();
  const popover = usePopover<HTMLButtonElement>();

  const [handleLogout, { error, resetError }] =
    useAsyncCallback(async (): Promise<void> => {
      popover.handleClose();

      await firebase.signOut();
      // reset notifications after successful logout
      await notifications.handleLogout();
      // this should force login
      navigate(paths.auth.firebase.login);
    }, [navigate, popover, firebase, notifications]);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
        >
          <Icon icon={icons.user} />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onLogout={handleLogout}
        onClose={popover.handleClose}
        open={popover.open}
      />
      <SimpleSnackbar
        open={!!error}
        onClose={resetError}
        severity="error"
        disableClickAway
      >
        Abmeldung fehlgeschlagen
      </SimpleSnackbar>
    </>
  );
};
